<template>
  <div>
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <span></span>
              <h4 class="mt-3">
                Empresas Integradas com
                <i style="font-size:15px" class="fa fa-project-diagram mx-1"></i> 
                Whatsapp 
                <img src="@/assets/images/whatsapp.png" width="20" alt="">
              </h4>
              <div class="icons-companies d-flex" >
                <router-link to="/admin/integrations" class="nav-link m-0 p-2"
                  title="Integração Whatsapp">
                    <img src="@/assets/images/whatsapp.png" width="23" alt="">
                </router-link>
                <router-link to="/admin/integrations" class="nav-link m-0 p-2"
                  title="Integração Instagram">
                    <img src="@/assets/images/instagram.png" width="23" alt="">
                </router-link>
                <router-link to="/admin/integrations" class="nav-link m-0 p-2"
                  title="Integração Messenger">
                    <img src="@/assets/images/messenger.png" width="23" alt="">
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header ">
                <div class="d-flex justify-content-between">
                  <h3 style="margin-top: 5px" class="card-title">Listagem de urls das integrações com whatsapp</h3>
                  <div style="width: 400px;" class="search input-group input-group-sm">
                    <input type="text" class="form-control"
                      placeholder="Pesquisar url..." v-model="searchTerm">
                    
                    <div class="input-group-append">
                      <button class="btn btn-primary" @click="getFilterCompanies()"
                         title="Pesquisar url">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                    
                  </div>
                  <!-- <button class="btn btn-outline-primary btn-sm" title='Atualizar'
                    @click.prevent="getCompaniesIntegrations()">
                    <i class="fa fa-undo"></i>
                  </button> -->
                </div>
              </div>

              <!-- /.card-header -->
              <!-- <div class="card-body">
                <table id="example2" class="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th>Empresa</th>
                      <th>Tempo</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody v-if="!companies || companies.length == 0 || companies == [] ">
                    <tr >
                      <td align="center" colspan="4">Nenhuma Companhia Encontrada</td>
                    </tr>
                  </tbody>

                  <tbody v-if="companies">
                    <tr v-for="(company, i) in companies" :key="i">
                      <td>{{company.name}}</td>
                      <td>{{new Date()}}</td>
                      <td style="padding: 13px 25px">
                        <button style="width:100%;" :class="status_qrcode_internet(company.integrations).btn"
                          data-toggle="tooltip" data-placement="right" 
                          :title="status_qrcode_internet(company.integrations).title" >
                          {{status_qrcode_internet(company.integrations).label}}</button>
                      </td>
                      <td>
                        <router-link tag="button" 
                          :to="{ name: 'details_integrations', params: {id: company.company_id}}" 
                          class="btn btn-outline-info btn-sm" 
                          title='Detalhes companhia'>
                            Detalhes
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
            <div v-for="(url, keyUrl) in filterUrlsCompanies" :key="keyUrl" class="card car-collapse collapsed-card">
              <div class="card-header">
                <h3 class="card-title">{{url.base_url}}</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip">
                    <i class="fas fa-plus"></i></button>
                  <!-- <button type="button" class="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                    <i class="fas fa-times"></i></button> -->
                </div>
              </div>
              <div class="card-body ">
                <table id="example2" class="table table-bordered table-hover">
                  <thead>
                    <tr >
                      <th >Id</th>
                      <th class="col-8">Empresa</th>
                      <th class="col-4">Ações</th>
                    </tr>
                  </thead>
                  <tbody v-if="!url.companies || url.companies.length == 0 || url.companies == [] ">
                    <tr >
                      <td align="center" colspan="4">Nenhuma Companhia Encontrada</td>
                    </tr>
                  </tbody>

                  <tbody v-if="url.companies">
                    <tr v-for="(company, key) in url.companies" :key="key">
                      <td >{{company._id}}</td>
                      <td class="col-8">{{company.name}}</td>
                      <td class="col-4">
                        <router-link tag="button" 
                          :to="{ name: 'details_integrations', params: {id: company._id, server_url: url.base_url}}" 
                          class="btn btn-outline-info btn-sm" 
                          title='Detalhes companhia'>
                            Detalhes
                        </router-link>
                      </td>
                      <!-- <td style="padding: 13px 25px">
                        <button style="width:100%;" :class="status_qrcode_internet(company.integrations).btn"
                          data-toggle="tooltip" data-placement="right" 
                          :title="status_qrcode_internet(company.integrations).title" >
                          {{status_qrcode_internet(company.integrations).label}}</button>
                      </td> -->
                      <!-- <td>
                        <router-link tag="button" 
                          :to="{ name: 'details_integrations', params: {id: company.company_id}}" 
                          class="btn btn-outline-info btn-sm" 
                          title='Detalhes companhia'>
                            Detalhes
                        </router-link>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                
              </div>
              <!-- /.card-footer-->
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <!-- <router-view /> -->
  </div>
</template>

<script>
export default {
	data() {
		return {
      companies: [],
      admin: {},
      companiesGroup: [],
      searchCompanies: '',
      searchTerm: '',

      urls_companies: []
		};
  },

  computed: {
    filterUrlsCompanies() {
      return this.urls_companies.filter(url => {
        return url.base_url.match(new RegExp(this.searchTerm, "ig"))
      })
    }
  },

	methods: {
		getCompaniesIntegrations() {
      this.$tallos_admin_api.defaults.headers.common[ "Authorization"] = 
        `Bearer ${this.$store.getters.tokenJWT}`;
        
      this.$tallos_admin_api.get(`integrations/all`)
        .then(res => {
          this.companies = res.data.companies
          this.companiesGroup = res.data.companies
        })
        .catch(e => {
          console.log(e)
          this.$toasted.global.defaultError({msg: 'Erro ao buscar companhias e integrações.'})
        })
    },
    
    status_qrcode_internet(integrations) {

      let status_qrcode = integrations.map(integ => {
        return integ.qr_code_status
      })

      
      let status_qr_final = status_qrcode.reduce((acumulador ,sqr) => {
        return acumulador += sqr
      }, 0)

      let status_internet = integrations.map(integ => {
        return integ.internet_status
      })
      
      let status_net_final = status_internet.reduce((acumulador ,net) => {
        return acumulador += net
      }, 0)

      if(status_qr_final == 0 && status_net_final == 0) {
        return {
          label: 'off',
          btn: 'btn btn-danger btn-xs',
          title: 'Desabilitado'
        }
      } else if((status_qr_final > 0 && status_qr_final == status_qrcode.length )
          && (status_net_final > 0 && status_net_final == status_internet.length)) {
          return {
            label: 'on',
            btn: 'btn btn-success btn-xs',
            title: 'Habilitado'
          }
      } else {
        return {
            label: 'in',
            btn: 'btn btn-secondary btn-xs',
            title: 'Inativo'
          }
      }

    },

    getFilterCompanies() {
      
      if (this.searchCompanies === '') {
        this.getCompaniesIntegrations()
      } else if (this.searchCompanies !== '') {
        this.companies = [...this.companiesGroup]
        const filterCompanies = this.companies.filter((companies, index) => {
          return companies.name.match(new RegExp(this.searchCompanies, "i"))
        })
        this.companies = filterCompanies
      } else {
        
      }
    },

    getUrlIntegrations() {
      this.$tallos_admin_api.defaults.headers.common[ "Authorization"] = 
        `Bearer ${this.$store.getters.tokenJWT}`;
        
      this.$tallos_admin_api.get(`integrations/whatsapps/servers`)
        .then(response => {
          this.urls_companies = response.data
        })
        .catch(e => {
          console.log(e)
          this.$toasted.global.defaultError({msg: 'Erro ao buscar urls de integrações.'})
        })
    },
  
  },

	created() {
    this.getUrlIntegrations()
    document.title = 'Tallos Admin - Empresas com Integrações de Whatsapp'
	}
};
</script>

<style scoped>

.content-wrapper {
  background-color: #fff1;
}

.div-header {
    background-color: #fff;
    padding: 5px;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 5px;
}
</style>